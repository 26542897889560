const AppRoutes = {
  LOGIN: "/",
  SIGNUP: "/signup",
  CLIENT_LOGIN: "/client-login",
  DASHBOARD: "/dashboard",
  AGREEMENT_VIEWER: "/agreement-viewer",
  AGREEMENT_DOWNLOAD: "/agreement-download",
  PAYMENT: "/Payment",
  FORGOT_PASSWORD: "/forgot-password",
  RESET_PASSWORD: "/reset-password",
  CONTACTS: "/contacts",
  CLIENTS: "/clients",
  Approved_Customers: "/approved-customers",
  BULK_CONTACT: "/contacts/bulk-add",
  CONTACTS_DETAILS: "/contacts-details",
  CONTACTS_IMPORT_LIST: "/contacts/contact-import-list",
  CONTACTS_IMPORT_HISTORY: "/contacts/contact-import-history",
  CUSTOMER: "/customer",
  QUALITYCONTROL: "/qualitycontrol",
  CLIENT_BULK_CONTACT: "/customer/client-bulk-add",
  SETTINGS: "/settings",
  CALENDAR: "/calendar",
  ACTIVITIES: "/activities",
  INBOX: "/inbox",
  DEALS: "/deals",
  TEMPLATES: "/templates",
  CONTRACT: "/contract",
  ACTIVITY: "/activity",
  AS_ACTIVITY: "/as-activity",
  PAYMENT_HISTORY: "/payment-history",
  PAYMENT_METHOD: "/payment-method",
  INVOICEPDF: "/invoicePdf",
  REPORTS: "/reports",
  // SALES_PERFORMANCE_REPORT: "/sales-performance-report",
  SUGGESTIONS_MANAGER: "/suggestions-manager",
  ASSIGN_AS: "/assign-as",
  ALLOCATED_AS: "/allocated-as",
  PAY_NOW: "/pay-now",
  VIDEOCALL: "/video-call",
  CALL_CAMPAIGN: "/call-campaign",
  BULK_CAMPAIGN: "/call-campaign/bulk-add",
  IMPORT_HISTORY: "/call-campaign-import-history",
  CALL_CAMPAIGN_DETAILS: "/call-campaign-details",
  CALL_CAMPAIGN_CONTACT_DETAILS: "/customer-details",
  AS_CALENDAR: "/as-calendar",
  All_SEARCHES: "/all-searches",
  SEE_ALL_AGREEMENT: "/all-searches/SeeAllAgreement",
  SEE_ALL_TABLE_CONTACT: "/all-searches/SeeAllContacts",
  SEE_ALL_CAMPAIGN: "/all-searches/SeeAllCampaign",
  SEE_ALL_DEALS: "/all-searches/SeeAllDeals",
  SEE_ALL_AGENTS: "/all-searches/SeeAllAgents",
  SEE_ALL_SALES: "/all-searches/SeeAllSales",
  SEE_ALL_USERS: "/all-searches/SeeAllUsers",

  // reports routes
  AGENT_REPORT: "agent-report",
  CAMPAIGN_REPORT: "campaign-report",
  CONTACT_REPORT: "contact-report",
  TIME_STATUS_REPORT: "time-status-report",
  SALES_PERFORMANCE_REPORT: "sales-performance-report",

  // settings routes
  PERMISSIONS: "permissions",
  SCREEN_ACCESS: "screen-access",
  FORM_BUILDER: "form-builder",
  CUSTOM_FIELDS: "custom-fields",
  ROLES: "roles",
  LEAD_SOURCE: "lead-source",
  LEAD_STATUS: "lead-status",
  LEAD_GROUP: "lead-group",
  SCREENS: "screens",
  TEAM: "team",
  PIPELINES: "pipelines",
  TAGS: "tags",
  ATTACHMENTS: "attachments",
  DISPOSITIN_CATEGORY: "disposition-category",
  CAMPAIGN_TIME_SLOT: "campaign-time-slot",
  GLOBAL_DISPOSITION: "global-disposition",
  POWER_DIALERS: "power-dialers",
  POWER_CALLING_DIALER: "/power-calling-dialer",
  SEQUENCE: "sequence",
  SEQUENCE_CONTACTS: "sequence-contacts",
  SEQUENCEDETAILS: "details",
  CAMPAIGN: "campaign",
  POST_CARD: "post-card",
  UPLOAD_DESIGN: "upload-design",
  LETTER: "letter",
  GIFT: "gift",
  LETTER_DESIGN: "letter-design",
  CAMPAIGNDETAILS: "campaign-details",
  MY_COMPANY: "company",
  FORWARD_NUMBER: "forward-number",
  CALL_OUTCOME: "call_outcomes",
  CALLER_ID: "caller-ids",
  CALL_LOGS: "call-logs",
  POWER_DIALER: "power-dialer",
  VIRTUAL_NUMBER: "virtual-number",
  CALL_RESULTS: "call-results",
  MARKETPLACE: "marketplace",
  DND: "dnd",
  RECONCILIATION: "reconciliation",
  CALLING_SCRIPT: "calling-script",
  SELECTE_CONTACT_LIST_BY_DRIPCAMPAIGN: "selected-contact-list",
  TRIGGER: "trigger",
  BLACK_LIST: "black-list",
  EMAIL_CONFIGURATION: "email-configuration",
  // profile
  // PROFILE: "profile",
};

export default AppRoutes;

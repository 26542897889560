import { Box, Button, Typography } from "@mui/material";
import { SocketContext } from "SocketContex";
import VideConference from "pages/user/inbox/component/video-conference";
import { useContext } from "react";

function InComingConfCall() {
  const {
    inComingConfCall,
    videoConfId,
    onConfCall,
    me,
    setOnConfCall,
    setInComingConfCall,
    rejectConf,
    acceptConfCall,
  } = useContext(SocketContext);
  const handlePopupOpen = () => {
    setInComingConfCall(false);
    acceptConfCall();
  };

  return (
    <>
      {inComingConfCall ? (
        <Box
          sx={{
            minHeight: "130px",
            width: "300px",
            position: "fixed",
            right: "25px",
            bottom: "80px",
            padding: "16px 25px",
            // border: "1px solid black",
            boxShadow: "0 1px 6px #00000040",
            borderRadius: "10px",
            background: "#fff",
          }}
        >
          <Typography paddingBottom="1rem">
            {videoConfId?.fromUserName} is conference call on{" "}
            {videoConfId?.groupName}
          </Typography>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "5px",
            }}
          >
            <Button
              sx={{
                backgroundColor: "#129677",
                color: "#fff",
                "&:hover": {
                  backgroundColor: "#129677",
                },
              }}
              onClick={handlePopupOpen}
            >
              Accept
            </Button>
            <Button
              sx={{
                backgroundColor: "#d45685",
                color: "#fff",
                "&:hover": {
                  backgroundColor: "#d45685",
                },
              }}
              onClick={() => rejectConf(videoConfId?.roomId || "", me)}
            >
              Decline
            </Button>
          </Box>
        </Box>
      ) : null}

      {onConfCall ? (
        <VideConference setShowConf={setOnConfCall} socketConnectionId={me} />
      ) : null}
    </>
  );
}

export default InComingConfCall;

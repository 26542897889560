import { IResponse } from "constants/interfaces";
import { IMoveToOtherCompany } from "pages/user/contacts/components/types/AddMyListRequest";
import client from "utils/ApiClient";
import API_URLS from "./endPoints";

export function moveToOtherCompany(
  body: IMoveToOtherCompany,
): Promise<IResponse> {
  return client.post(API_URLS.MOVE_TO_OTHER_COMPANY, body);
}

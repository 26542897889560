import { PER_PAGE } from "constants/constants";
import { IResponse } from "constants/interfaces";
import { IResponseSalesAgent } from "pages/admin/dashboard/quary/useFetchActiveSalesAgents";
import { IAgentReportResponse } from "pages/admin/reports/screens/agent-report/query/useFetchAgentReport";
import { IAddCallSchedule } from "pages/call-manager/hook/useAddCallScheduleForm";
import { IResponseCallSchedule } from "pages/call-manager/query/useFetchCallBackList";
import client from "utils/ApiClient";
import API_URLS from "./endPoints";

export interface IAgentParams {
  from: any;
  to: any;
  pageNo: number;
  pageLimit: number;
  search: string;
  role: string;
}

export interface IToggleContactStatus {
  contactId: number;
  isSms: boolean | null;
  isEmail: boolean | null;
  isRecording: boolean | null;
}

export interface IAddCallTranscription {
  to: string;
  from: string;
  confId: string;
  type: string;
  userId: number;
  companyId: number;
  transcriptMessage: number;
}

async function fetchSuggestions(payload: any): Promise<any> {
  const URL = payload?.importCustomerFileId
    ? `${API_URLS.GET_LIVE_CALL_SUGGESTIONS}?transcription=${payload?.transcription}&userId=${payload?.userId}&importCustomerFileId=${payload?.importCustomerFileId}`
    : `${API_URLS.GET_LIVE_CALL_SUGGESTIONS}?transcription=${payload?.transcription}&userId=${payload?.userId}`;
  return client.get(URL);
}

async function getSuggestionsList(payload: any): Promise<any> {
  return client.post(
    "https://localuser02-001-site1.etempurl.com/api/triggers/getall",
  );
}
// GET_CALL_LOGS
async function getCallLogs(): Promise<any> {
  return client.get(`${API_URLS.CALL_LOG_LIST}`);
}
async function saveSuggestions(payload: any): Promise<any> {
  return null;
}
async function deleteSuggestions(payload: any): Promise<any> {
  return null;
}

async function getTwilioToken(payload: any): Promise<any> {
  return client.get(`${API_URLS.TWILIO_TOKEN}?identity=${payload.identity}`);
}

async function updateModule(payload: any): Promise<any> {
  return client.put(`${API_URLS.UPDATE_MODULE}`, payload);
}

async function getActiveSalesAgentList(): Promise<IResponseSalesAgent> {
  return client.get(`${API_URLS.ACTIVE_AGENT_LIST}`);
}

async function getAdminAccordionList(): Promise<IResponseSalesAgent> {
  return client.get(`${API_URLS.ADMIN_ACCORDIAN_LIST}`);
}

async function updateCallSid(values: any, from: string): Promise<any> {
  return client.put(
    from === "outbond"
      ? `${API_URLS.UPDATE_CALL_SID}`
      : `${API_URLS.UPDATE_INCOMINGCALL_BY_CALL_SID}`,
    values,
  );
}

async function getCompanyDetailsByConfId(confId: string): Promise<any> {
  return client.get(`${API_URLS.GET_COMPANY_DETAILS_BY_CONFID}/${confId}`);
}
async function UpdateDispositionSid(id: any): Promise<any> {
  return client.put(`${API_URLS.UPDATE_DISPOSITION_SID}`, id);
}

async function UpdateCallNoteById(id: any): Promise<any> {
  return client.put(`${API_URLS.UPDATE_CALL_NOTES_BY_ID}`, id);
}

async function startCallWrap(confId: any, userId: number): Promise<any> {
  return client.put(
    `${API_URLS.START_CALL_WRAP}?confId=${confId}&userId=${userId}`,
  );
}

async function updateCallNote(id: any): Promise<any> {
  return client.put(`${API_URLS.CALL_NOTE}`, id);
}
// const URL = "https://crm-lime-chi.vercel.app/token"; ACTIVE_AGENT_LIST
async function holdUnhold(values: any): Promise<any> {
  return client.post(`${API_URLS.HOLD_UNHOLD}`, values);
}
async function callEnd(values: any) {
  return client.post(`${API_URLS.CALL_END}`, values);
}
async function blindTransfer(values: any): Promise<any> {
  return client.post(`${API_URLS.BLIND_TRANSFER}`, values);
}
async function getCallInfo(userId: number): Promise<any> {
  return client.get(`${API_URLS.GET_CALL_INFO}?userId=${userId}`);
}

async function getAgentCallBySid(sid: string): Promise<any> {
  return client.get(`${API_URLS.GET_AGENT_CALL_BY_SID}/${sid}`);
}

async function addCallSchedule(body: IAddCallSchedule): Promise<IResponse> {
  const url = `${API_URLS.ADD_CALL_SCHEDULE}`;
  return client.post(url, body);
}

export async function fetchCallBackList(
  userId: number,
  pageNo: number,
  pageLimit: number,
): Promise<IResponseCallSchedule> {
  const url = `${API_URLS.FETCH_ALL_CALL_BACK_LIST}?userId=${userId}&page=${pageNo + 1
    }&pageSize=${pageLimit || PER_PAGE}`;
  return client.get(url);
}

async function fetchDispositionCodes(): Promise<IResponse> {
  const url = `${API_URLS.GET_DISPOSITION_CODES}`;
  return client.get(url);
}

async function toggleContact(body: IToggleContactStatus): Promise<IResponse> {
  const url = `${API_URLS.TOGGLE_CONTACT_STATUS}?contactId=${body.contactId}&isEmail=${body.isEmail}&isSms=${body.isSms}&isRecording=${body.isRecording}`;
  return client.put(url);
}

async function fetchAgentReport(
  params: IAgentParams,
): Promise<IAgentReportResponse> {
  let url = `${API_URLS.FETCH_ALL_AGENT_REPORT}?page=${params?.pageNo
    }&pageSize=${params?.pageLimit || PER_PAGE}&role=${encodeURI(params.role)}`;
  if (params?.from) {
    url += `&From=${params?.from}`;
  }
  if (params?.to) {
    url += `&To=${params?.to}`;
  }
  if (params?.search) {
    url = `${url}&search=${params?.search}`;
  }
  return client.get(url);
}
async function addCallTranscription(
  body: IAddCallTranscription,
): Promise<IResponse> {
  return client.post(API_URLS.ADD_CALL_TRANSCRIPTION, body);
}
export {
  UpdateCallNoteById, UpdateDispositionSid, addCallSchedule,
  addCallTranscription,
  blindTransfer,
  callEnd,
  deleteSuggestions,
  fetchAgentReport,
  fetchDispositionCodes,
  fetchSuggestions,
  getActiveSalesAgentList,
  getAdminAccordionList,
  getAgentCallBySid,
  getCallInfo,
  getCallLogs,
  getCompanyDetailsByConfId,
  getSuggestionsList,
  getTwilioToken,
  holdUnhold,
  saveSuggestions,
  startCallWrap,
  toggleContact,
  updateCallNote, updateCallSid, updateModule
};


import { PER_PAGE } from "constants/constants";
import { IResponse } from "constants/interfaces";
import { ResendPayment } from "pages/user/contacts/query/useApiAction";
import client from "utils/ApiClient";
import API_URLS from "./endPoints";

async function fetchPaymentByContactId(
  page: number,
  pageSize: number,
  search = "",
  roleName?: string,
  contactId?: number | string,
): Promise<IResponse> {
  // let url = "";

  // switch (roleName) {
  //   case "Client":
  //     url = `${API_URLS.GET_ALL_PAYMENT_HISTORY}?contactId=${contactId}&page=${
  //       page + 1
  //     }&pageSize=${pageSize || PER_PAGE}`;
  //     break;
  //   default:
  //     // url = `${API_URLS.GET_ALL_PAYMENT_HISTORY}`;
  //     url = `${API_URLS.GET_ALL_PAYMENT_HISTORY}?page=${page + 1}&pageSize=${
  //       pageSize || PER_PAGE
  //     }`;
  //     break;
  // }
  let url =
    roleName === "Client"
      ? `${API_URLS.GET_ALL_PAYMENT_HISTORY}?contactId=${contactId}&page=${
          page + 1
        }&pageSize=${pageSize || PER_PAGE}`
      : `${API_URLS.GET_ALL_PAYMENT_HISTORY}?page=${page + 1}&pageSize=${
          pageSize || PER_PAGE
        }`;

  if (search) {
    url = `${url}&search=${search}`;
  }

  return client.get(url);
}

async function sendPayment(body: ResendPayment): Promise<IResponse> {
  const url = `${API_URLS.RESEND_PAYMENT}`;
  return client.post(url, body);
}

async function fetchPaymentHistoryByContactId(
  contactId?: number,
): Promise<IResponse> {
  const url = `${API_URLS.GET_ALL_PAYMENT_HISTORY}?contactId=${contactId}`;
  return client.get(url);
}

async function fetchAssignHistoryByContactId(
  contactId?: number,
): Promise<IResponse> {
  const url = `${API_URLS.ASSIGNED_HISTORY}?contactId=${contactId}`;
  return client.get(url);
}

export {
  fetchAssignHistoryByContactId,
  fetchPaymentByContactId,
  fetchPaymentHistoryByContactId,
  sendPayment,
};

import { useApiActions } from "pages/user/contacts/query/useApiAction";
import { useQueryClient } from "react-query";

const useGlobalActions = () => {
  const { trySavePreferences } = useApiActions();
  const queryClient = useQueryClient();
  async function handlePreferences<T>(name: string, value: T) {
    await trySavePreferences({
      preferenceName: name,
      preferences: JSON.stringify(value),
    });
  }

  const modifyUserOnlineOffline = (
    key: any,
    userId: number,
    status: string,
    callStatus: string,
  ) => {
    const cachedChatsData = queryClient.getQueryData<any>(key);

    if (cachedChatsData) {
      const { data: pages } = cachedChatsData;
      console.log("cachedChatsData", pages);

      const updatedPages = pages.map((event: any) => {
        if (event.userId === userId) {
          const newObj = {
            ...event,
            loginStatus: status,
            status: callStatus || event.status,
          };
          // return {...event, totalAttendees: (Number(event.totalAttendees) + 1).toString()};
          return newObj;
        }
        return event;
      });

      const updateFeed = { ...cachedChatsData, data: updatedPages };
      queryClient.setQueryData<any>(key, updateFeed);
    }
    return queryClient.getQueryData(key);
  };

  return {
    modifyUserOnlineOffline,
    handlePreferences,
  };
};

export { useGlobalActions };

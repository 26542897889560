/**
 * @format
 */
import useDecodedData from "hooks/useDecodedData";
import { useQuery } from "react-query";
import { fetchVirtualNumbers } from "services/callerIds.service";
import { QueryKeys } from "utils/QueryKeys";

export interface IVirtualNumberResponse {
  statusCode: number;
  data: string[];
  totalCount: number;
}

async function getVirtualNumbers(
  clientId: number,
  userId: number,
): Promise<IVirtualNumberResponse | undefined> {
  try {
    const response: IVirtualNumberResponse = await fetchVirtualNumbers(
      clientId,
      userId,
    );
    return response;
  } catch (error) {
    return Promise.reject(error);
  }
}

const useFetchVirtualNumbers = (clientId: number, enabled = false) => {
  const decoded = useDecodedData();
  const userId = Number(decoded?.id);
  const cacheKey = [QueryKeys.virtualNumbersAsCallerIds, userId];
  return useQuery(
    cacheKey,
    () => {
      return getVirtualNumbers(clientId, userId);
    },
    {
      enabled,
    },
  );
};

export { useFetchVirtualNumbers };


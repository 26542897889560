import { Box } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import { Transition } from "components/layouts/popup-modals/Transition";
import * as React from "react";
import { LeftTransition } from "./LeftTransition";

interface ISlider {
  open: any;
  children: React.ReactNode;
  size?: "xl" | "lg" | "sm" | "md" | "xs";
  noHeight?: boolean;
  extraSmall?: string;
  backgroundColor?: string;
  fullWidth?: boolean;
  onClose?: () => void;
  zIndex?: number;
  leftOpen?: boolean;
  paperSx?: any;
  toTop?: boolean;
  id?: string;
}

function Slider(props: ISlider) {
  const {
    open,
    children,
    size,
    noHeight,
    extraSmall,
    backgroundColor = "#F6F7FB",
    fullWidth,
    onClose,
    zIndex,
    leftOpen = false,
    paperSx,
    toTop,
    id,
  } = props;

  return (
    <Box
      id={id}
      sx={{
        backgroundColor,
      }}
    >
      <Dialog
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        fullWidth={fullWidth}
        maxWidth={size || "lg"}
        open={open}
        PaperProps={{
          sx: {
            margin: 0,
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
            height: noHeight ? null : "100vh",
            width: extraSmall || "calc(90% - 64px)",
            backgroundColor,
            zIndex: zIndex || 99999,
            ...paperSx,
            // overflow: "hidden" // TODO do not use overflow here
          },
        }}
        sx={{
          top: 0,
          "& .MuiDialog-container.MuiDialog-scrollPaper": {
            backgroundColor: "rgba(163, 197, 255, 0.4)",
            justifyContent: leftOpen ? "flex-start" : "flex-end",
            ...(noHeight && {
              alignItems: leftOpen
                ? "flex-start"
                : toTop
                  ? "flex-start"
                  : "flex-end",
            }),
            height: noHeight ? null : "auto",
          },
        }}
        TransitionComponent={leftOpen ? LeftTransition : Transition}
        onClose={onClose}
      >
        {children}
      </Dialog>
    </Box>
  );
}

export default Slider;

/* eslint-disable prettier/prettier */
/* eslint-disable jsx-a11y/media-has-caption */
import { makeStyles } from "@material-ui/core";
import CallIcon from "@mui/icons-material/Call";
import PersonIcon from "@mui/icons-material/Person";
import { Avatar, Box, Typography } from "@mui/material";
import { SocketContext } from "SocketContex";

import useDecodedData from "hooks/useDecodedData";
import { useContext, useEffect, useState } from "react";
import { getInitials } from "utils/get-initials";
import VideoCallRinging from "../video-call-ringing";

interface IProps {
  isCameraOn: boolean; // Receive the isCameraOn prop
}

const useStyles = makeStyles((theme) => ({
  video: {
    width: "100%",
    [theme.breakpoints.down("xs")]: {
      width: "100%",
    },
    height: "100%",
    borderRadius: "8px",
    boxShadow:
      "box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;",
  },
  videoUser: {
    width: "100%",
    height: "100%",
    // objectFit: "unset",
    display: "inline-block",
  },
  gridContainer: {
    justifyContent: "center",
    [theme.breakpoints.down("xs")]: {
      flexDirection: "column",
    },
  },
  paper: {
    padding: "1px",
    margin: "10px",
    boxShadow:
      "box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;",
  },
}));

function PlayVideo(props: IProps) {
  const [videoId, setVideoId] = useState(`my-video-${new Date().getTime()}`);
  const decoded = useDecodedData();
  const [videoFromId, setVideoFromId] = useState(
    `my-video-${new Date().getTime()}`,
  );

  const {
    callAccepted,
    myVideo,
    userVideo,
    callEnded,
    stream,
    call,
    isVideo,
    me,
    userCameraOn,
    cameraOn,
    toUserName,
    userScreenShareOn,
  } = useContext(SocketContext);

  const classes = useStyles();

  useEffect(() => {
    if (myVideo.current && stream) {
      myVideo.current.srcObject = stream;
      myVideo.current.muted = true;
    }
  }, [stream]);

  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({ video: cameraOn || isVideo, audio: true })
      .then((stream) => {
        if (myVideo.current) {
          myVideo.current.srcObject = stream;
          myVideo.current.muted = true;
        }
      })
      .catch((error) => {
        console.log("Error getting user video call media:", error);
      });
  }, [myVideo, cameraOn, isVideo, callAccepted, callEnded]);

  return (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        border: "1px solid #bdbdbd",
        position: "relative",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          top: 10,
          right: 15,
          zIndex: 100,
          height: isVideo ? "300px" : "0px",
          width: isVideo ? "350px" : "0px",
          borderRadius: 2,
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            zIndex: 900,
            height: !cameraOn ? "100%" : "0px",
            width: !cameraOn ? "100%" : "0px",
            display: cameraOn ? "none" : "block",
            backgroundColor: "#fff",
            borderRadius: 2,
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              height: "100%",
              width: "100%",
              flexDirection: "column",
              boxShadow: 8,
            }}
          >
            <Avatar>{getInitials(decoded?.FullName)}</Avatar>
            <Typography>{decoded?.FullName}</Typography>
          </Box>
        </Box>

        {callAccepted && !callEnded ? (
          <video
            key={videoId}
            ref={myVideo}
            autoPlay
            playsInline
            className={classes.video}
            id={videoId}
          />
        ) : null}
      </Box>
      {callAccepted && !callEnded ? (
        <Box
          sx={{
            position: "relative",
            width: "100%",
            height: "100%",
            overflow: "hidden",
          }}
        >
          {!call.isVideoCall && !userScreenShareOn ? (
            <Box
              sx={{
                width: "100%",
                height: "100%",
                position: "absolute",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "#fff",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  border: "1px solid #000",
                  p: 1,
                  height: 180,
                  width: 180,
                  borderRadius: 360,
                }}
              >
                <PersonIcon sx={{ fontSize: "40px" }} />
                <Typography>
                  {me !== call.from ? call.name : decoded?.FullName}
                </Typography>
              </Box>
            </Box>
          ) : null}

          <video
            key={videoFromId}
            ref={userVideo}
            autoPlay
            playsInline
            className={classes.videoUser}
            id={videoFromId}
          />

          <Box
            sx={{
              position: "absolute",
              top: 0,
              right: 0,
              zIndex: 10,
              height: !userCameraOn && !userScreenShareOn ? "100%" : "0px",
              width: !userCameraOn && !userScreenShareOn ? "100%" : "0px",
              display: userCameraOn && !userScreenShareOn ? "none" : "block",
              backgroundColor: "#fff",
              borderRadius: 2,
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "100%",
                width: "100%",
                flexDirection: "column",
                boxShadow: 8,
              }}
            >
              <Avatar>
                {getInitials(me === call.from ? toUserName : call?.name)}
              </Avatar>
              <Typography>
                {me === call.from ? toUserName : call?.name}
              </Typography>
            </Box>
          </Box>

          {/* */}
        </Box>
      ) : (
        <>
          <VideoCallRinging>
            <CallIcon />
          </VideoCallRinging>
        </>
      )}
    </Box>
  );
}

export default PlayVideo;

import { useSnackbar } from "components/snackbar";
import ErrorMessages from "constants/ErrorMessages";
import { IResponse } from "constants/interfaces";
import useDecodedData from "hooks/useDecodedData";
import { useQueryClient } from "react-query";
import { useContactActions } from "redux/contacts/contacts";

import {
  bulkImportContact,
  deleteBulkContact,
  removeContact,
  saveContact,
  savePreference,
} from "services/contacts.service";
import { AddDealRequest, addDeal } from "services/deal.service";
import { moveToOtherCompany } from "services/moveOtherCompany.service";
import {
  addMyList,
  deleteMyList,
  editMyList,
  saveMyList,
} from "services/myList.service";
import { sendPayment } from "services/paymentHistory.service";
import { saveUser } from "services/team.service";
import { QueryKeys } from "utils/QueryKeys";
import {
  AddMyListRoot,
  IEditMyListRoot,
  IMoveToOtherCompany,
} from "../components/types/AddMyListRequest";
import { IContactAddRoot } from "../components/types/ContactAddRequest";

export interface IPreference {
  preferenceName: string;
  preferences: string;
}

export interface ResendPayment {
  paymentId?: number;
  origin?: string;
}

const useApiActions = () => {
  const snackbar = useSnackbar();
  const queryClient = useQueryClient();
  const decode = useDecodedData();
  const { resetContactIds } = useContactActions();

  const addDealAction = async (data: AddDealRequest): Promise<boolean> => {
    try {
      const response = await addDeal(data);
      if (response.statusCode === 200) {
        queryClient.invalidateQueries([QueryKeys.users]);
        snackbar?.show({
          title: response?.message,
          type: "success",
        });
        return true;
      }
    } catch (error: any) {
      snackbar?.show({
        title: error.message || "Something went wrong!",
        type: "error",
      });
    }
    return false;
  };

  const tryCreate = async (values: any) => {
    try {
      const response: IResponse = await saveUser(values);
      if (response.statusCode === 200) {
        queryClient.invalidateQueries([QueryKeys.users]);
        snackbar?.show({
          title: ErrorMessages.team.success,
          type: "success",
        });
      }
    } catch (err: any) {
      const msg = err?.message || "Something went wrong";
      snackbar?.show({
        title: msg,
        type: "error",
      });
    }
  };

  const trySavePreferences = async (values: IPreference) => {
    try {
      const response = await savePreference({
        ...values,
        userId: Number(decode.id),
      });
      queryClient.invalidateQueries([QueryKeys.preferences]);
      return response;
    } catch (err: any) {
      const msg = err?.message || "Something went wrong";
      snackbar?.show({
        title: msg,
        type: "error",
      });
      return err;
    }
  };

  const addContactAction = async (data: IContactAddRoot): Promise<any> => {
    try {
      const response = await saveContact({
        ...data,
        recordOwnerId: 0,
        userId: Number(decode?.id),
      });
      if (response.statusCode === 200) {
        queryClient.invalidateQueries([QueryKeys.contacts]);
        snackbar?.show({
          title: response?.message,
          type: "success",
        });
        return response;
      }
    } catch (err: any) {
      const msg = err?.message || "Something went wrong";
      snackbar?.show({
        title: msg,
        type: "error",
      });
      return false;
    }
    return false;
  };

  const editMyListAction = async (
    data: IEditMyListRoot,
    isRemoved: boolean,
  ): Promise<boolean> => {
    try {
      const response = await editMyList(
        {
          ...data,
          userId: Number(decode?.id),
        },
        isRemoved,
      );
      if (response.statusCode === 200) {
        queryClient.invalidateQueries([QueryKeys.contacts]);
        queryClient.invalidateQueries([QueryKeys.myList]);
        snackbar?.show({
          title: response.message,
          type: "success",
        });
        return true;
      }
      return false;
    } catch (err: any) {
      const msg = err?.message || "Something went wrong";
      snackbar?.show({
        title: msg,
        type: "error",
      });
    }
    return false;
  };

  const addMyListAction = async (data: AddMyListRoot): Promise<boolean> => {
    try {
      const response = await saveMyList(data);
      if (response.statusCode === 200) {
        queryClient.invalidateQueries([QueryKeys.contacts]);
        queryClient.invalidateQueries([QueryKeys.myList]);
        snackbar?.show({
          title: response.message,
          type: "success",
        });
        return true;
      }
      return false;
    } catch (err: any) {
      const msg = err?.message || "Something went wrong";
      snackbar?.show({
        title: msg,
        type: "error",
      });
    }
    return false;
  };

  const addListAction = async (data: AddMyListRoot): Promise<any> => {
    try {
      const response = await addMyList({
        ...data,
        userId: Number(decode.id),
      });
      if (response.statusCode === 200) {
        // queryClient.invalidateQueries([QueryKeys.myList]);
        snackbar?.show({
          title: response.message,
          type: "success",
        });
        return response;
      }
      return {
        statusCode: 400,
        message: "Something went wrong",
        data: {},
      };
    } catch (err: any) {
      const msg = err?.message || "Something went wrong";
      snackbar?.show({
        title: msg,
        type: "error",
      });
    }
    return {};
  };

  const removeMyListAction = async (id: number): Promise<boolean> => {
    try {
      const response = await deleteMyList(id);
      if (response.statusCode === 200) {
        queryClient.invalidateQueries([QueryKeys.myList]);
        snackbar?.show({
          title: response.message,
          type: "success",
        });
        return true;
      }
      return false;
    } catch (err: any) {
      const msg = err?.message || "Something went wrong";
      snackbar?.show({
        title: msg,
        type: "error",
      });
    }
    return false;
  };

  const bulkRemoveContacts = async (data: any): Promise<boolean> => {
    try {
      const response = await deleteBulkContact(data);
      if (response.statusCode === 200) {
        queryClient.invalidateQueries([QueryKeys.contacts]);
        queryClient.invalidateQueries([QueryKeys.getAllDuplicate]);
        snackbar?.show({
          title: response.message,
          type: "success",
        });
        return true;
      }
      return false;
    } catch (err: any) {
      const msg = err?.message || "Something went wrong";
      snackbar?.show({
        title: msg,
        type: "error",
      });
    }
    return false;
  };
  const tryBulkImportContacts = async (data: any): Promise<string> => {
    try {
      const response = await bulkImportContact({
        file: data,
        userId: Number(decode.id),
      });
      if (response) {
        queryClient.invalidateQueries([QueryKeys.contacts]);
        snackbar?.show({
          title: response,
          type: "success",
        });
        return response;
      }
      return response;
    } catch (err: any) {
      const msg = err || "Something went wrong";
      snackbar?.show({
        title: msg,
        type: "error",
      });
    }
    return "";
  };

  const removeContactAction = async (id: number): Promise<boolean> => {
    try {
      const response = await removeContact(id);
      if (response.statusCode === 200) {
        queryClient.invalidateQueries([QueryKeys.contacts]);
        snackbar?.show({
          title: response.message,
          type: "error",
        });
        return true;
      }
      return false;
    } catch (err: any) {
      const msg = err?.message || "Something went wrong";
      snackbar?.show({
        title: msg,
        type: "error",
      });
    }
    return false;
  };

  const tryReSendPayment = async (body: ResendPayment) => {
    try {
      const response: IResponse = await sendPayment(body);
      if (response.statusCode === 200) {
        queryClient.invalidateQueries([QueryKeys.paymentHistory]);
        snackbar?.show({
          title: response.message || "",
          type: "success",
        });
      }
    } catch (err: any) {
      const msg = err?.message || "Something went wrong";
      snackbar?.show({
        title: msg,
        type: "error",
      });
    }
  };

  const tryMoveToOtherCompany = async (
    data: IMoveToOtherCompany,
  ): Promise<any> => {
    try {
      const response = await moveToOtherCompany(data);
      if (response.statusCode === 200) {
        queryClient.invalidateQueries([QueryKeys.contacts]);
        resetContactIds();
        snackbar?.show({
          title: response.message,
          type: "success",
        });
        return response;
      }
      return {
        statusCode: 400,
        message: "Something went wrong",
        data: {},
      };
    } catch (err: any) {
      const msg = err?.message || "Something went wrong";
      snackbar?.show({
        title: msg,
        type: "error",
      });
    }
    return {};
  };

  return {
    tryReSendPayment,
    trySavePreferences,
    tryCreate,
    addContactAction,
    addMyListAction,
    removeMyListAction,
    removeContactAction,
    bulkRemoveContacts,
    tryBulkImportContacts,
    addDealAction,
    addListAction,
    tryMoveToOtherCompany,
    editMyListAction,
  };
};

export { useApiActions };


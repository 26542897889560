/**
 * @format
 */
import useDecodedData from "hooks/useDecodedData";
import { useQuery } from "react-query";
import { fetchDisposition } from "services/disposition.service";
import { QueryKeys } from "utils/QueryKeys";
import { IDispositionRequest } from "../hooks/useAddDispositionForm";

export interface IResponseDisposition {
  data: IDispositionRequest[];
  totalCount: number;
  statusCode: number;
  message: any;
}

async function getDisposition(
  callCampaignID: number,
  userId: number,
  pageNo: number,
  pageLimit = 10,
  searchKeyword?: string,
): Promise<IResponseDisposition | undefined> {
  try {
    const response: IResponseDisposition = await fetchDisposition(
      callCampaignID,
      userId,
      pageNo,
      pageLimit,
      searchKeyword,
    );
    if (response.statusCode === 200) {
      return response;
    }
    return {
      data: [],
      totalCount: 0,
      statusCode: 500,
      message: "",
    };
  } catch (error) {
    return Promise.reject(error);
  }
}

const useFetchDisposition = (
  callCampaignID: number,
  pageNo: number,
  pageLimit = 10,
  enabled = true,
  searchKeyword?: string,
) => {
  const cacheKey = [QueryKeys.getAllDisposition];
  const decoded = useDecodedData();
  return useQuery(
    cacheKey,
    () => {
      return getDisposition(
        callCampaignID,
        decoded?.id,
        pageNo,
        pageLimit,
        searchKeyword,
      );
    },
    {
      enabled,
      refetchOnWindowFocus: false,
    },
  );
};

export { useFetchDisposition };


import { INewChat } from "pages/user/inbox";
import { createContext } from "react";
import {
  ICallerIdWithCampaign,
  IRefetch,
  SocketContextProps,
} from "./interface.socket";

export const initialValue = {
  userToCall: "",
  isReceivingCall: false,
  from: "",
  name: "",
  signal: {},
  isVideoCall: false,
  toUser: "",
};

export const SocketContext = createContext<SocketContextProps>({
  setCallerIdWithCampaign: (callerId: ICallerIdWithCampaign[]) => {},
  callerIdWithCampaign: [],
  reset: () => {},
  setSelectedCallerId: (callerId: string) => {},
  selectedCallerId: "",
  neverBeenContacted: false,
  setNeverBeenContacted: (data: any) => {},
  sort: null,
  setSort: (data: any) => {},
  refetchBunch: null,
  setRefetchBunch: (data: IRefetch) => {},
  filterUrl: null,
  setFilterUrl: () => {},
  setNewChatMessage: (data: INewChat) => {},
  newChatMessage: null,
  chatRooms: [],
  setChatRooms: () => {},
  call: {
    userToCall: "",
    isReceivingCall: false,
    from: "",
    name: "",
    signal: {},
    isVideoCall: false,
  },
  openCallScreen: false,
  userCameraOn: false,
  callAccepted: false,
  callRejected: false,
  inComingConfCall: false,
  seconds: 0,
  callPopup: true,
  voiceCallMiniPopup: true,
  myVideo: { current: null },
  userVideo: { current: null },
  stream: undefined,
  name: "",
  whoIsTyping: "",
  setName: () => {},
  fromName: "",
  setFromName: () => {},
  callEnded: false,
  cameraOn: false,
  onConfCall: false,
  setCameraOn: () => {},
  audioOn: false,
  setAudioOn: () => {},
  me: "",
  toUser: "",
  toUserName: "",
  videoConfId: "",
  twilioCallState: null,
  device: null,
  twilioCallConnection: null,
  setTwilioCallConnection: () => {},
  toNumber: "",
  setToNumber: () => {},
  fromNumber: "",
  setFromNumber: () => {},
  incomingData: null,
  setInComingData: () => {},
  callCutByClient: false,
  setCallCutByClient: () => {},
  callerIds: [],
  setCallerIds: () => {},
  endPopUp: false,
  setEndPopUp: () => {},
  twilioRinging: false,
  setTwilioRinging: () => {},
  acceptedCall: null,
  setAcceptedCall: () => {},

  setDevice: () => {},
  callUser: () => {},
  setToUserName: () => {},
  setSeconds: () => {},
  acceptConfCall: () => {},
  leaveCall: () => {},
  makeConfCall: () => {},
  setVideoConfId: () => {},
  rejectCall: () => {},
  rejectConf: () => {},
  answerCall: () => {},
  switchCamera: () => {},
  switchCameraOff: () => {},
  switchAudioOn: () => {},
  switchAudioOff: () => {},
  setCallRejected: () => {},
  setOpenCallScreen: () => {},
  setOnConfCall: () => {},
  setInComingConfCall: () => {},
  setWhoIsTyping: () => {},
  setTwilioCallState: () => {},
  handleOutGoingCallHangup: () => {},
  handleOutGoingCall: () => {},
  handleDTMFNum: (num: string) => {},
  handleInAcceptComingCall: () => {},
  handleLeaveConf: () => {},
  shareScreen: (isShare: boolean) => {},
  screenShareEnabled: false,
  signalRConnection: "",
  setSignalRConnection: () => {},
  userScreenShareOn: false,
  setUserScreenShareOn: () => {},
  sendMessage: () => {},
});

import { IResponse } from "constants/interfaces";
import client from "utils/ApiClient";
import API_URLS from "./endPoints";

//* Get Deal Request
export interface IGetRequest {
  LeadStatusId: string;
  From: string;
  To: string;
  search: string;
  contactId: any;
}

//* Get Deal Response
export interface IResponseDealsRoot {
  statusCode: number;
  data: IDealsData[];
  totalDocs: {
    id: number;
    dealCount: number;
    totalDealValue: number;
  };
}

export interface IDealsData {
  id: number;
  userId: number;
  contactId: number;
  pipeLineId: number;
  pipelineName: string;
  leadStatusId: number;
  leadStatusName: string;
  fullName: string;
  email: any;
  phone: any;
  dealTitle: string;
  dealValue: number;
  estimateCloseDate: string;
  status: number;
  dealStatus: number;
  createdBy: any;
  createdOn: string;
  updatedBy: any;
  updatedOn?: string;
}

//* Add Deal Request
export interface AddDealRequest {
  id?: number;
  userId: number;
  contactId: number;
  pipeLineId: number;
  leadStatusId: number;
  dealTitle: string;
  dealValue: number;
  estimateCloseDate: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
  leadSourceId?: number;
}

//* Update Deal Lead Status Request
export interface UpdateDealLeadStausRequest {
  id?: number;
  userId?: number;
  contactId?: number;
  pipeLineId?: number;
  leadStatusId?: number;
  dealTitle?: string;
  dealValue?: number;
  estimateCloseDate?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
}

//* get all leadstatus ascending
export interface LeadStatusAscRoot {
  statusCode: number;
  data: LeadStatusAscData[];
  message: string;
}

export interface LeadStatusAscData {
  id: number;
  leadStatusName: string;
  dealdoc: Dealdoc;
}

export interface Dealdoc {
  id: number;
  dealCount: number;
  totalDealValue: number;
}

//* won lost lead update
export interface WonLostLeadRequest {
  id: number;
  contactId: number;
  wonLostId: number;
  dealId: number;
}

export async function getDeals(
  requestUrl: string,
): Promise<IResponseDealsRoot> {
  let url = `${API_URLS.GET_DEAL}`;
  if (requestUrl) {
    url = `${url}?${requestUrl}`;
  }
  return client.get(url);
}

export async function addDeal(body: AddDealRequest): Promise<IResponse> {
  const url = `${API_URLS.ADD_DEAL}`;
  return client.post(url, body);
}

export async function updateDealLeadStatus(
  body: UpdateDealLeadStausRequest,
): Promise<IResponse> {
  const url = `${API_URLS.UPDATE_LEAD_STATUS}`;
  return client.patch(url, body);
}

export async function editDeal(
  body: UpdateDealLeadStausRequest,
): Promise<IResponse> {
  const url = `${API_URLS.EDIT_DEAL}`;
  return client.put(url, body);
}

export async function getAllLeadStatusAscending(
  restUrl: string,
): Promise<LeadStatusAscRoot> {
  let url = `${API_URLS.GET_ALL_LEADSTATUS_ASCENDING}`;
  if (restUrl) {
    url = `${url}?${restUrl}`;
  }
  return client.get(url);
}

export async function deleteDeal(id: number): Promise<IResponse> {
  const url = `${API_URLS.DELETE_DEAL_BY_ID}/${id}`;
  return client.delete(url);
}

export async function wonLostLead(
  body: WonLostLeadRequest,
): Promise<IResponse> {
  const { id, ...restBody } = body;
  const url = `${API_URLS.WON_LOST_LEAD}/${id}`;
  return client.patch(url, restBody);
}
